import './App.css';
import React, { useState } from 'react';
import Slider from './components/Slider/Slider'
import logo from './components/duck.png'
import menu from './components/menu.png'
import Events from './components/Events';
import avik from './About/krishp.jpeg'
import drishti from './About/sampadaa.jpeg'
import kelly from './About/saks-comp.jpg'
import sachi from './About/chotes-sec.jpg'
import Collapsible from './Committees/Collapsible';
import codeed from './Committees/Untitled presentation.png'
import opensource from './Committees/opensourcebanner.png'
import goods from './Committees/techedupdated.jpeg'
import cyber from './Committees/cyber.PNG'
import gwc from './Committees/girlswhocodebanner.jpeg'
import webdev from './Committees/webdev.PNG'
import appdev from './Committees/appdevbanner.jpeg'
import usaco from './Committees/usacotempbanner.jpeg'
import ch1 from './Committees/sophiasong.jpeg'
import ch2 from './Committees/Aditya_Photo_HOPE.jpg'
import ch3 from './Committees/hannnah-cybersec.jpg'
import ch4 from './Committees/rifag.jpeg'
import ch5 from './Committees/ashley-usaco.jpg'
import ch6 from './Committees/donovanz.jpeg'
import ch7 from './Committees/advayv.jpeg'
import ch8 from './Committees/pratikae.jpeg'
import ch9 from './Committees/riag2.png'
import ch10 from './Committees/Pradyun-webdev.jpg'
import ch11 from './Committees/arihanvwd.png'
import ch12 from './Committees/roshan-appdev.jpg'
import ch13 from './Committees/akhilk.jpeg'
import ch14 from './Committees/rishabhpic.jpeg'

// NOTES:
// - About, Blog, Commitees, HackTAMS (special box)
// - Footer w/ socials and contact info
// - Main page w/ what is CSO info, promoVid, and upcoming events/facebook, and slideshow maybe
// - about page with same what is CSO info and exec intros
// - commitee page w/ list of commitee's, commitee head info and description/ fb link
// - Blog go to the blog i think 
// - hacktams special box w/ redirect to hacktams page


const ShowHome = () => {
  const [show, setShow] = useState(false);

  if (window.location.pathname === "/") {
    return (
      <div className='content'>
        
            <div className='first-section'>
              <div className='slider-item'>
              <h1 className="slider-head"><strong className='purple'>Gallery</strong></h1>
              <Slider />
              </div>
              <div className="cso-desc" id='cso-desc' onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                <h1 className="desc-head"><strong className='green'>What is CSO?</strong></h1>
                <p className="desc-text">Welcome to the 2023-2024 CSO Website! The TAMS Computer Science Organization (CSO) exposes students to a wide array of <a className={show && 'txv'}>opportunities</a> within <a className={show && 'txv'}>computer science</a> such as hackathons, competitive programming, volunteering, and much more! It doesn't matter if you have <a className={show && 'txv'}>no experience</a> or you aren't on the CS track. See you in the fall!</p>
                <h1 className="desc-head"><strong className='blue'>What do we do?</strong></h1>
                <p className="desc-text">CSO holds a variety of events, GAs, and competitions throughout the year. Our biggest event, <a className={show && 'tvv'}>HackTAMS</a>, takes place in late January or early February. Make sure to visit the HackTAMS website for more information. Apart from HackTAMS, CSO also hosts <a className={show && 'tvv'}>bi-weekly GAs</a> where you can learn more about computer science! We also have a number of <a className={show && 'tvv'}>committees</a> that do different activities (check out the committees page), and a <a className={show && 'tvv'}>competitions</a> team that competes throughout the year!</p>
              </div>
            </div>
            
            
            <div className='third-section'>
            <div className='upcoming-events' id='upcoming-events'>
                <h1 className="upcoming-head"><strong className='red'>Upcoming Events</strong></h1>
                <Events />
              </div>  
              <div className='fb' id='fb'>
              <div id="fb-root"></div>
                {(function(d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s);
                    js.id = id;
                    js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0&appId=1265760814201130&autoLogAppEvents=1';
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'))}
              
              <h1 className="fb-head"><strong className='yellow'>FaceBook Group</strong></h1>
              <div className="fb-group" 
                data-href="https://www.facebook.com/groups/213178531130343" 
                data-width="380" 
                data-show-metadata="false"
                data-skin="dark">
              </div>
              </div>
                
                          
            </div>
        
            <div className="promo-vid">
            <h1 className="desc-head"><strong className='other'>Promo Video</strong></h1>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/49E2OB6tjak" title="YouTube video player" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
      </div>
    );
  }
}

const showAbout = () => {
  if (window.location.pathname === "/about") {
    return(
      <div className='about-content'>
      <div className='left'>
        <div className='content left-content'>
          <h1><strong className='blue t'>Krish Patel - President</strong></h1>
          <div className='desc'>Hey guys! My name is Krish, and I am going to be your 2023-2024 CSO president. I am from Wylie High School and I am on the computer science track at TAMS. I will also be a game development committee head and will be overseeing Web and App Dev. Outside of school I love playing video games, tennis, and working out. Don’t be afraid to reach out if you have any questions, because I would love to get to know you guys.
 </div>
        </div>
        <div className='pic left-pic' >
          <img src={avik} alt='avik' width="280px"/>
        </div>
      </div>

      <div className='right'>
      <div className='pic right-pic' >
        <img src={drishti} alt='drishti' width="276px"/>
      </div>
      <div className='content right-content'>
        <h1><strong className='green t'>Sampadaa Prakash - Hackathon Director</strong></h1>
        <div className='desc'>Hi everyone! My name is Sampadaa Prakash, and I will be your Hackathon Director this school year! I am from Katy, Texas, where I used to go to Seven Lakes High School. This year I will also be your TAS (2100) Computer Science Committee Head, Eureka! Volunteer Director, and a Game Development committee head (I'll also be overseeing Tech-Ed and Girls Who Code). In my free time, I like to sing, work out, eat good food, and listen to music (Drake, The Weeknd, Don Toliver, Tyler, Frank Ocean, etc). I am a huge space nerd and love contemplating the unexplained phenomena in our universe. Feel free to reach out if you have any questions, or just want to talk! 
Instagram: @sampadaap
Facebook: Sampadaa Prakash
 </div>
      </div>
      
      </div>

      <div className='left'>
        <div className='content left-content'>
          <h1><strong className='purple t'>Saksham Shori - Logistics Director</strong></h1>
          <div className='desc'>Hey everyone! My name is Saksham and I’ll be your Competition/Logistics Director for the 2023-2024 school year. I’ll be leading the Competition team as well as the Cybersecurity and USACO committees. Outside of academics I’m the captain of the Basketball team and love to play the drums and listen to music. Feel free to reach out with any questions!

Insta: Saksham.shori 
Facebook: Saksham Shori
:)</div>
        </div>
        <div className='pic left-pic' >
          <img src={kelly} alt='kelly' width="235px"/>
        </div>
      </div>

      <div className='right'>
      <div className='pic right-pic' >
        <img src={sachi} alt='sachi' width="290px"/>
      </div>
      <div className='content right-content'>
        <h1><strong className='red t'>Victor Tangton - Secretary</strong></h1>
        <div className='desc'>Hello! I'm Victor Tangton, your Secretary for this year! Originally from Plano, Texas, I attended Lebanon Trail High School. Aside from my role as Secretary, I also hold positions as the soccer captain, Game Development Committee head, DECA Committee head, and oversee Code-Ed, Good Samaritan, and Open Source initiatives. During my free time, I enjoy working out, spending time with friends, and listening to a variety of artists such as Brent Faiyaz, Sonders, Daniel Caesar, Joji, 21 Savage, Tyler, the Creator, 😍beabadoobee😍, and more. If you have any questions or need assistance, don't hesitate to reach out! I'm thrilled to meet all of you.
 </div>
      </div>
      </div>

      </div>
    )
  }
}

const showCommittees = () => {
  if (window.location.pathname === "/committees") {
    return (
      <div className='committees-content'>
        <Collapsible label="Code-Ed" col="green" >
          <div className='first-row'>
            <div className='comm-desc'>
              <h2 className='comm-desc-title'><strong className='red'>Description</strong></h2>
              <div className='comm-desc-cont'>We are the TAMS Code-Ed committee under CSO. We help TAMS students get opportunities to teach students in Denton about programming! Code-Ed aims to provide students across Denton with educational programming opportunities in Python, Scratch, and Web Development. We will do this by having TAMS students volunteer to teach programming topics on a weekly basis, which will foster a spirit of volunteerism and allow them to refresh the basics of computer science.</div>
            </div>
              <img src={codeed} alt='codeed' width="410px"/>
          </div>
          <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'>Committee Head(s)</strong></h2>
              <img src={ch1} alt="ch1" width="300px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Sophia Song</strong></h2>
              <div className='t-content'>
              Hi! My name is Sophia Song, and I’m one of your Code-Ed and Tech-Ed committee heads this year! I am on the Computer Engineering track, and I am also a senior mentor, volleyball captain, cal 1/2 tutor, and Sports Club exec. I love listening to music (fav artists: taylor and conan) and playing volleyball! Feel free to reach out if you have any questions or just wanna talk!
 
              </div>
            </div>
            </div>
            
            <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'></strong></h2>
              <img src={ch2} alt="ch1" width="300px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Aditya Singirikonda</strong></h2>
              <div className='t-content'>
              Hello, my name is Aditya, and I am super excited to be one of your Code-Ed committee heads this year. I am on the computer science track at TAMS, and I love to run and watch the Cowboys dominate(only during the regular season 😞 #WeDemBoyz ). If you have any questions or concerns about Code Ed or anything in general, please feel free to contact me.
              </div>
            </div>
            </div>
        </Collapsible>
        <Collapsible label="Cybersecurity" col="blue">
        <div className='first-row'>
            <div className='comm-desc'>
              <h2 className='comm-desc-title'><strong className='red'>Description</strong></h2>
              <div className='comm-desc-cont'>We are the TAMS Cybersecurity committee under CSO. We are here to introduce you to the fascinating world of cybersecurity through workshops and competitions. We hope to introduce our peers to cybersecurity through lessons dedicated to the basics of ethical and competitive hacking, as well as competitions like Cyberpatriot, CyberStart America, and various CTFs. Come and join if you would like to push the boundaries of your knowledge in computer science beyond what you are taught.</div>
            </div>
              <img src={cyber} alt='codeed' width="400px"/>
          </div>
          <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'>Committee Head(s)</strong></h2>
              <img src={ch3} alt="ch1" width="300px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Hannah Ahn</strong></h2>
              <div className='t-content'>
              Hi, everyone! My name is Hannah Ahn, and I'll be your Cybersecurity committee head this year. I'm a senior in the Computer Engineering track. In my free time, I really enjoy playing video games, reading manga, and learning more about cybersecurity. If you have any questions about anything, feel free to ask me. Looking forward to meeting everyone this year!
 
              </div>
            </div>
            </div>
        </Collapsible>
        <Collapsible label="Tech-Ed" col="purple">
        <div className='first-row'>
            <div className='comm-desc'>
              <h2 className='comm-desc-title'><strong className='red'>Description</strong></h2>
              <div className='comm-desc-cont'>Tech-Ed is a committee under the Computer Science Organization which aims to document the growth and development of the CS community at TAMS through media including news stories, feature stories, videos, Q&A's, and more! Through a blog site and YouTube channel, Tech-Ed intends to honor/highlight efforts that students are putting toward CS and inspire other TAMS students to pursue this field. By putting out a continuous stream of CS content, Tech-Ed hopes to capture each individual’s experiences, thoughts, growth, and progress.
</div>
            </div>
              <img src={goods} alt='codeed' width="500px"/>
          </div>
          <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'>Committee Head(s)</strong></h2>
              <img src={ch4} alt="ch1" width="400px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Rifa Gowani</strong></h2>
              <div className='t-content'>
              Hello everyone! My name is Rifa Gowani, and I am thrilled to be your Tech-Ed Committee Head for the exciting academic year of 2023-2024. I am a proud alumnus of Jasper High School and am currently in the Traditional Science Pathway at TAMS. I also hold the position of Co-Competitions Director for the TAMS Research Organization. 

Away from school, I love music and tv. I'm always on the hunt for new shows or music. Don't hesitate to share your top music recommendations or must-watch TV shows with me.

I encourage you to contact me with any questions, concerns, or thoughts (I can girl a lot about new tech a lot)  you may have about tech I'm here to help you and hope to create an unforgettable year!

I am looking forward to meeting you!


My Social Media:
Instagram: rifa.gowani
Email: rifagowani@my.unt.edu 
              </div>
            </div>
            </div>
            <div className='second-row'>
            <div className='t-i'>
              <img src={ch1} alt="ch1" width="300px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Sophia Song</strong></h2>
              <div className='t-content'>
              Hi! My name is Sophia Song, and I’m one of your Code-Ed and Tech-Ed committee heads this year! I am on the Computer Engineering track, and I am also a senior mentor, volleyball captain, cal 1/2 tutor, and Sports Club exec. I love listening to music (fav artists: taylor and conan) and playing volleyball! Feel free to reach out if you have any questions or just wanna talk!
 
              </div>
            </div>
            </div>
        </Collapsible>
        <Collapsible label="Open Source" col="ye">
        <div className='first-row'>
            <div className='comm-desc'>
              <h2 className='comm-desc-title'><strong className='red'>Description</strong></h2>
              <div className='comm-desc-cont'>We are the TAMS Open Source committee under CSO. Our mission is to contribute to massive open source projects like Missing Maps, Wikisource, and the National Archives. No computer science experience is needed for any of these opportunities, and if you need any help, we're here for you! We'll be hosting monthly editathons this year, so feel free to join us if you just want to hang out. Volunteering hours will be determined by the number of contributions made to either project.</div>
            </div>
              <img src={opensource} alt='codeed' width="400px"/>
          </div>
          <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'>Committee Head(s)</strong></h2>
              <img src={ch7} alt="ch1" width="300px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Advay Vyas</strong></h2>
              <div className='t-content'>
              Hey! I'm Advay Vyas, one of your CSO Open Source committee heads this year! I'm from Coppell High School, and I'm also on the CS track! Outside of CSO, I'm a senior mentor and the president of Ballot. I'm really interested in history and how society develops, but I'm always down to make really bad jokes! I love teaching math, and I'm happy to help with any level of math (just give me some advance notice if it's outside my usual range!) In my free time, I like reading, watching randomly generated comedy, and listening to music. I'm excited to get to know all of you!

My Social Media:
Email: advay.vyas@gmail.com
Instagram: @advay.vyas
Facebook: Advay Vyas

              </div>
            </div>
            </div>
            
            <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'></strong></h2>
              <img src={ch6} alt="ch1" width="180px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Donovan Zhang</strong></h2>
              <div className='t-content'>
              Hello fellow TAMsters! My name is Donovan Zhang and I’m excited to be one of your Open Source Committee Heads this year. I’m from Allen High School in (you guessed it) Allen and I am currently part of the CS track. In my free time, I enjoy hanging out with friends, playing games, and messing around with newfangled technology. Don’t be afraid to let me know if you have any questions about Open Source (or anything else) and I look forward to meeting you all! Sparkle on and don’t forget to be yourself!
              </div>
            </div>
            </div>
        </Collapsible>
        <Collapsible label="Girls Who Code" col="hue">
        <div className='first-row'>
            <div className='comm-desc'>
              <h2 className='comm-desc-title'><strong className='red'>Description</strong></h2>
              <div className='comm-desc-cont'>We are the TAMS Girls Who Code committee under CSO. We help empower girls pursue and learn more about computer science. Whether it be holding workshops and events or introducing them to CS competitions and careers. Come join us for this great experience! Our mission is to encourage girls in the Denton area to pursue careers in computer science by working with the national Girls Who Code nonprofit organization and volunteering with local schools. Come join us in our efforts to close the gender gap in computer science and change the image of modern programmers! </div>
            </div>
              <img src={gwc} alt='codeed' width="500px"/>
          </div>
          <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'>Committee Head(s)</strong></h2>
              <img src={ch8} alt="ch1" width="300px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Pratika Eswaran</strong></h2>
              <div className='t-content'>
              hey! i’m pratika, one of your girls who code committee heads for 2023-2024 :)) i’m originally from reedy hs in frisco and am on the computer science track. in my free time, i love listening/making music, going to the gym, and playing games. this year, i’ll also be the vice president for TAMS Culinary Society along with committee head for Music Everywhere, TAMS Masala, and Peels! feel free to reach out if you ever need help or just wanna talk
              </div>
            </div>
            </div>
            
            <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'></strong></h2>
              <img src={ch9} alt="ch1" width="290px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Ria Garg</strong></h2>
              <div className='t-content'>
              Hi y’all! I’m Ria and I’ll be one of your 2023-2024 Girls Who Code committee heads. I’m on the computer science track and love listening to pop music, traveling, and getting boba with friends 🙂 I’ll also be involved with MAO and Student Council as an exec and TMS and pROfiLE as a committee head. Looking forward to meeting and getting to know y’all!
              </div>
            </div>
            </div>

        </Collapsible>
        <Collapsible label="USACO" col="red">
        <div className='first-row'>
            <div className='comm-desc'>
              <h2 className='comm-desc-title'><strong className='red'>Description</strong></h2>
              <div className='comm-desc-cont'>We are the TAMS USACO committee under CSO. We provide help to TAMS students interested in participating in the USA Computing Olympiad, an online programming competition in line for qualifying for the International Olympiad for Informatics. Whether you are a beginner with no knowledge of computer science or an expert with years of experience, we're just the place for you to grow! In fact, we will do this by having our experts have the opportunity to support our junior members and have regular assemblies for reviewing material and providing aid for studying.
Come count cows with us!</div>
            </div>
              <img src={usaco} alt='codeed' width="430px"/>
          </div>
          <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'>Committee Head(s)</strong></h2>
              <img src={ch5} alt="ch1" width="300px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Ashley Zhao</strong></h2>
              <div className='t-content'>
              Hello! I’m Ashley and I will be one of your USACO Committee Heads this year! Aside from this, I will also serve as your TAMS Academic Society President (come study with us) and VIBE Choreography Head (come dance with us). I used to attend Independence High School in Frisco, and aside from code, dance, and library rat, I also design/make clothes and listen to an absurd amount of music. Don’t hesitate to reach out if you have any questions!

Facebook: Ashley Zhao
Instagram: _ashley_zhao_

              </div>
            </div>
            </div>
            
            <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'></strong></h2>
              <img src={ch14} alt="ch1" width="285px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Rishabh Mallidi</strong></h2>
              <div className='t-content'>
              Hey! I am Rishabh, and I will be one of your 2023-24 USACO committee heads (as well as your Speech and Debate committee head). Before TAMS, I went to Coppell High School. I'm on the Traditional Science track (but weirdly switched physics for bio). In my free time, though, you can find me in the media library playing val or at willis grinding. I love to socialize and help, so don't be afraid to reach out with any questions! 

Email: rishabhmallidi@gmail.com
Instagram: rishabh_m88
Facebook: Rishabh Mallidi

              </div>
            </div>
            </div>
        </Collapsible>
        <Collapsible label="Web-Dev" col="wo">
        <div className='first-row'>
            <div className='comm-desc'>
              <h2 className='comm-desc-title'><strong className='red'>Description</strong></h2>
              <div className='comm-desc-cont'>We are the TAMS Web Dev committee under CSO. We help TAMS students learn or enhance their coding skills to become experts in the field of Web Development. Come join us for a fun experience! We hope to shine a light on this amazing field of computer science by showing the wonderful aspects of its application in the real world and to provide a delightful, enjoyable experience in a newly found passion and ambition.</div>
            </div>
              <img src={webdev} alt='codeed' width="400px"/>
          </div>
          <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'>Committee Head(s)</strong></h2>
              <img src={ch10} alt="ch1" width="270px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Pradyun Tandra</strong></h2>
              <div className='t-content'>
              I'm Pradyun, Web Wizard,  Gym Rat, and Frisbee Champ 💪. I have experience using numerous Web Development languages and frameworks to build different projects. You can check out some of those projects here: https://linktr.ee/pradyunt. Look out for Web Dev video guides and workshops that will take you from no knowledge to making your own Web Applications. If you have any questions, feel free to message me.

              </div>
            </div>
            </div>
            
            <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'></strong></h2>
              <img src={ch11} alt="ch1" width="220px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Arihan Varanasi</strong></h2>
              <div className='t-content'>
              Hey ducks! I’m Arihan and I’m one of your 2023-2024 Web Dev Committee Heads. I’m a senior on the CS Track and enjoy building cool websites, playing soccer, and eating food.
I’m looking forward to helping y’all build some amazing stuff this year!

              </div>
            </div>
            </div>
        </Collapsible>
        <Collapsible label="App-Dev" col="other">
        <div className='first-row'>
            <div className='comm-desc'>
              <h2 className='comm-desc-title'><strong className='red'>Description</strong></h2>
              <div className='comm-desc-cont'>We are the TAMS Open Source committee under CSO. Our mission is to contribute to massive open source projects like Missing Maps, Wikisource, and the National Archives. No computer science experience is needed for any of these opportunities, and if you need any help, we're here for you! We'll be hosting monthly editathons this year, so feel free to join us if you just want to hang out. Volunteering hours will be determined by the number of contributions made to either project.</div>
            </div>
              <img src={appdev} alt='codeed' width="400px"/>
          </div>
          <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'>Committee Head(s)</strong></h2>
              <img src={ch12} alt="ch1" width="300px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Roshan Rajan</strong></h2>
              <div className='t-content'>
              Hello, my name is Roshan, and I am super excited to be one of your App Dev committee heads this year. I am on the computer science track at TAMS, and enjoy working out and wrestling. If you have any questions or concerns about App Dev or anything in general, please feel free to contact me.

              </div>
            </div>
            </div>
            
            <div className='second-row'>
            <div className='t-i'>
              <h2 className='comm-head-title'><strong className='purple'></strong></h2>
              <img src={ch13} alt="ch1" width="300px"/>
            </div>
            <div className='t-d'>
              <h2 className='ch-title'><strong className='yellow'>Akhil Krishnamurthy</strong></h2>
              <div className='t-content'>
              Hi! My name is Akhil and I will be your 2023-2024 App Dev Committee Head! I have experience with different languages such as Dart (Flutter), Swift, and React. However, when I’m not coding, I like going to the gym and playing basketball, volleyball, and chess. Let me know if you have any questions and I can’t wait to get started with you all!
              </div>
            </div>
            </div>
        </Collapsible>
      </div>
    )
  }
}


function App() {
  const [showLinks, setShowLinks] = useState(false); 
  
  return (
    <div className="App">
      <div class="topnav">
        <a href="/"><img src={logo} alt='logo' width='80px' className='logo'/></a>
        <div className='title'><a href="/" >TAMS | CSO</a></div>
        <div className='pages'>
        <a href="https://2023.hacktams.org/" className="y page special hover-1 " target="_blank">HackTAMS</a>
        <a href="https://csoblog.vercel.app/" className="page split" target="_blank">Blog</a>
        <a href="/committees" className="p page split">Committees</a>
        <a href="/about" className="b page split">About</a>
        </div>

        {/* <button onClick={() => setShowLinks(!showLinks)}>Open</button> */}
        <img src={menu} alt='menu' className='men' onClick={() => setShowLinks(!showLinks)}></img>
        <div className={showLinks ? 'showing' : 'not-showing'}>
          <div className='mobile-pages'>
            <a href="/about" className="b page four">About</a>
            <a href="/committees" className="p page three">Committees</a>
            <a href="https://csoblog.vercel.app/" className="page two">Blog</a>
            <a href="https://2023.hacktams.org/" className="y page one">HackTAMS</a>
          </div>
        </div>
      </div>
      
      <div className='real'>
        {ShowHome()}
        {showAbout()}
        {showCommittees()}
      </div>

      <footer class="footer">
  <div class="footer__addr">
    <h1 class="footer__logo">TAMS | CSO</h1>
      
      <div className='contac'>
    <h2>Contact</h2>
    
    <address>
    1705 W Sycamore St, Denton, TX 76201<br />
          
      <a class="footer__btn" href="mailto:tamscomputerscience@gmail.com">Email Us</a>
    </address>
    </div>
  </div>
  
  <ul class="footer__nav">
    <li class="nav__item">
      <h2 class="nav__title">Socials</h2>

      <ul class="nav__ul">
        <li>
          <a href="https://www.facebook.com/groups/213178531130343" target="_blank">Facebook</a>
        </li>

        <li>
          <a href="https://www.instagram.com/tamscso/" target="_blank">CSO Instagram</a> 
        </li>
            
        <li>
          <a href="https://www.instagram.com/hacktams/" target="_blank">HackTAMS Instagram</a>
        </li>
      </ul>
    </li>
    
    <li class="nav__item nav__item">
      <h2 class="nav__title">Quick Links</h2>
      
      <ul class="nav__ul nav__ul">
        <li>
          <a href="https://tams.club" target="_blank">tams.club</a>
        </li>
        
        <li>
          <a href="https://github.com/tams-cso" target="_blank">GitHub</a>
        </li>

        <li>
          <a href="https://unt.campuslabs.com/engage/organization/tams-computer-science" target="_blank">UNT OrgSync</a>
        </li>
      </ul>
    </li>

    <li class="nav__item">
      <h2 class="nav__title">HackTAMS</h2>
      
      <ul class="nav__ul">
        <li>
          <a href="https://hacktams.org" target="_blank">HackTAMS Website</a>
        </li>
        
        <li>
          <a href="mailto:hacktams@gmail.com" target="_blank">HackTAMS Email</a>
        </li>
        
        <li>
          <a href="https://www.facebook.com/groups/5862984087050740" target="_blank">HackTAMS Facebook</a>
        </li>
      </ul>
    </li>
    
    
  </ul>
  
  <div class="legal__links">
    <p>&copy; 2022 TAMS CSO. All rights reserved.</p>
  </div>
      </footer>

    </div>
  );
}

export default App;
